import React from 'react';
import Helmet from 'react-helmet';

class CloudHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      venderUrl: process.env.REACT_APP_VENDER_JS_URL,
      fetnetUiUrl: process.env.REACT_APP_FETMET_UI_JS_URL,
      adobeUrl: process.env.REACT_APP_ADOBE_ANALYTICS_URL,
      reactCssUrl: process.env.REACT_APP_CBU_REACT_CSS_URL,
      logoutURL: process.env.REACT_APP_LOGOUT_REDIRECT_URL, // 登出網址，預設為 eService 首頁
    };
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <link rel='stylesheet' href={this.state.reactCssUrl} />
          <script src={this.state.adobeUrl}></script>
          <script src={this.state.venderUrl}></script>
          <script src={this.state.fetnetUiUrl}></script>
        </Helmet>
        <header>
          <div className='aem-page page'>
            <div className='fui-header is-fixed'>
              <fetnet-header logoutCallbackUrl={this.state.logoutURL}></fetnet-header>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default CloudHeader;
