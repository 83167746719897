import { TYPES } from '../action';
import { allotInitialState } from '../state';

export default function allotReducer(state = allotInitialState, action) {
  switch (action.type) {
    case TYPES.SET_TRANSID:
      return Object.assign({}, state, {
        transId: action.id,
      });

    case TYPES.ADD_ACCOUNT:
      return Object.assign({}, state, {
        accounts: [...state.accounts, ...action.data],
        init: false,
      });

    case TYPES.TEMP_ACCOUNT:
      return Object.assign({}, state, {
        tempAccount: {
          index: action.index,
          ...action.account,
        },
      });

    case TYPES.UPDATE_ACCOUNT:
      let accounts = [...state.accounts];
      accounts[action.index] = action.account;
      return Object.assign({}, state, {
        accounts,
        init: false,
      });

    case TYPES.DEL_ACCOUNT:
      let account = state.accounts.splice(action.index, 1);
      return Object.assign({}, state, {
        accounts: account,
        init: false,
      });

    default:
      return state;
  }
}
