import React from 'react';
import Link from './Link';
import PropTypes from 'prop-types';
import * as IndexService from '../service/IndexService';

const Button = (props) => {
  const handleClick = (e) => {
    if (props.onClick) props.onClick(e);
  };

  const combileClass = () => {
    let className = 'fui-button ';
    if (props.btnStyle) className += `is-${props.btnStyle} `;
    if (props.size) className += `is-${props.size} `;
    if (props.reverse) className += `is-reverse `;
    if (props.className) className += props.className;

    return className;
  };

  const aaTrackingRenderer = () => {
    return props.link ? (
      <Link
        data-aa-tracking-id={`${props.dataTrackingId}`}
        to={props.link}
        className={combileClass()}
        disabled={props.disabled}
        target={props.target || '_self'}
        fetkey={props.fetkey != null ? props.fetkey : undefined}
        style={props.style}
      >
        <span className='text'>{props.children}</span>
        {props.btnStyle === 'text' ? <i className='icon-chevron-right'></i> : ''}
      </Link>
    ) : (
      <button
        data-aa-tracking-id={`${props.dataTrackingId}`}
        className={combileClass()}
        onClick={(e) => handleClick(e)}
        disabled={props.disabled}
        fetkey={props.fetkey != null ? IndexService.getFetkeyVariablePrefix() + props.fetkey : undefined}
      >
        <span className='text' fetkey={props.fetkey != null && IndexService.getFetkeyVariablePrefix() + props.fetkey}>
          {props.children}
        </span>
        {props.btnStyle === 'text' ? <i className='icon-chevron-right'></i> : ''}
      </button>
    );
  };

  const defaultRenderer = () => {
    return props.link ? (
      <Link
        to={props.link}
        className={combileClass()}
        disabled={props.disabled}
        target={props.target || '_self'}
        fetkey={props.fetkey != null ? props.fetkey : undefined}
      >
        <span className='text'>{props.children}</span>
        {props.btnStyle === 'text' ? <i className='icon-chevron-right'></i> : ''}
      </Link>
    ) : (
      <button
        className={combileClass()}
        onClick={(e) => handleClick(e)}
        disabled={props.disabled}
        fetkey={props.fetkey != null ? IndexService.getFetkeyVariablePrefix() + props.fetkey : undefined}
      >
        <span className='text' fetkey={props.fetkey != null && IndexService.getFetkeyVariablePrefix() + props.fetkey}>
          {props.children}
        </span>
        {props.btnStyle === 'text' ? <i className='icon-chevron-right'></i> : ''}
      </button>
    );
  };

  return props.dataTrackingId ? aaTrackingRenderer() : defaultRenderer();
};

Button.propTypes = {
  link: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  btnStyle: PropTypes.string, // primary | secondary | text | arrow | label | tag
  size: PropTypes.string, // large | small
  revers: PropTypes.bool, // default false
  disabled: PropTypes.bool, // default false
  onClick: PropTypes.func, //
  dataTrackingId: PropTypes.string, // for AA tracking
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default Button;
