import React, { Component } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as IndexService from '../service/IndexService';

/**
 * Link that also works for external URL's
 */
export default class Link extends Component {
  isExternalLink = (link) => {
    return !!link && (/\/\//.test(link) || link.indexOf('.pdf') > -1 || /((tel:|mailto:))/.test(link));
  };

  fetkeyRenderer() {
    return this.isExternalLink(this.props.to) ? (
      <a
        href={this.props.to}
        {...this.props}
        fetkey={this.props.fetkey != null ? IndexService.getFetkeyVariablePrefix() + this.props.fetkey : undefined}
      >
        {this.props.children}
      </a>
    ) : (
      <ReactLink
        {...this.props}
        fetkey={this.props.fetkey != null ? IndexService.getFetkeyVariablePrefix() + this.props.fetkey : undefined}
      >
        {this.props.children}
      </ReactLink>
    );
  }
  render() {
    return this.props.fetkey ? (
      this.fetkeyRenderer()
    ) : this.isExternalLink(this.props.to) ? (
      <a href={this.props.to} {...this.props}>
        {this.props.children}
      </a>
    ) : (
      <ReactLink {...this.props}>{this.props.children}</ReactLink>
    );
  }
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  target: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};
