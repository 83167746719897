import { combineReducers } from 'redux';
import helpCenterReducer from './helpCenter';
import formReducer from './form';
import analyticsReducer from './analytics';
import emmaReducer from './emma';
import allotReducer from './allot';

export const reducers = combineReducers({
  helpCenterReducer,
  formReducer,
  analyticsReducer,
  emmaReducer,
  allotReducer,
});
