// Types
export const SEARCH_HELP_CENTER_KEYWORD = 'SEARCH_HELP_CENTER_KEYWORD';
export const SEARCH_HELP_CENTER_HOTKEY = 'SEARCH_HELP_CENTER_HOTKEY';
export const SEARCH_HELP_CENTER_CATEGORY = 'SEARCH_HELP_CENTER_CATEGORY';
export const SEARCH_HELP_CENTER_CATEGORY_MENU = 'SEARCH_HELP_CENTER_CATEGORY_MENU';
export const SET_HELP_CENTER_SEARCHPATH = 'SET_HELP_CENTER_SEARCHPATH';

export const SEND_FORM_GENDER_DATA = 'SEND_FORM_GENDER_DATA';
export const SEND_FORM_SELECT_DATA = 'SEND_FORM_SELECT_DATA';
export const SEND_FORM_RECAPTCHA = 'SEND_FORM_RECAPTCHA';
export const SEND_FORM_AGREETEXT = 'SEND_FORM_AGREETEXT';
export const SEND_FORM_INPUT_DATA = 'SEND_FORM_INPUT_DATA';
export const SEND_FORM_CUSTOM_DATA = 'SEND_FORM_CUSTOM_DATA';

export const SET_DATALAYER = 'SET_DATALAYER';
export const SEND_CUSTOM_EVENT = 'SEND_CUSTOM_EVENT';
export const TRACK_PAGE_VIEW_START = 'TRACK_PAGE_VIEW_START';
export const TRACK_PAGE_VIEW_END = 'TRACK_PAGE_VIEW_END';
export const TRACK_ACTION_TRIGGER = 'TRACK_ACTION_TRIGGER';
export const TRACK_ACTION_BUTTON = 'TRACK_ACTION_BUTTON';
export const TRACK_PURCHASE_COMPLETED = 'TRACK_PURCHASE_COMPLETED';
export const TRACK_PURCHASE_FAILURE = 'TRACK_PURCHASE_FAILURE';
export const TRACK_SEARCH_ITEM_CLICK = 'TRACK_SEARCH_ITEM_CLICK';
export const TRACK_SHARE_BUTTON_CLICK = 'TRACK_SHARE_BUTTON_CLICK';
export const TRACK_FAQ_FILL_IN = 'TRACK_FAQ_FILL_IN';

export const SET_EMMA_DATA = 'SET_EMMA_DATA';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const TEMP_ACCOUNT = 'TEMP_ACCOUNT';
export const DEL_ACCOUNT = 'DEL_ACCOUNT';
export const OTP_COUNTDOWN = 'OTP_COUNTDOWN';
export const SET_TRANSID = 'SET_TRANSID';

export const TYPES = {
  SEARCH_HELP_CENTER_KEYWORD,
  SEARCH_HELP_CENTER_HOTKEY,
  SEARCH_HELP_CENTER_CATEGORY,
  SEARCH_HELP_CENTER_CATEGORY_MENU,
  SET_HELP_CENTER_SEARCHPATH,
  SEND_FORM_GENDER_DATA,
  SEND_FORM_SELECT_DATA,
  SEND_FORM_RECAPTCHA,
  SEND_FORM_AGREETEXT,
  SEND_FORM_INPUT_DATA,
  SEND_FORM_CUSTOM_DATA,
  SET_DATALAYER,
  SEND_CUSTOM_EVENT,
  TRACK_PAGE_VIEW_START,
  TRACK_PAGE_VIEW_END,
  TRACK_ACTION_TRIGGER,
  TRACK_ACTION_BUTTON,
  TRACK_PURCHASE_COMPLETED,
  TRACK_PURCHASE_FAILURE,
  TRACK_SEARCH_ITEM_CLICK,
  TRACK_SHARE_BUTTON_CLICK,
  TRACK_FAQ_FILL_IN,
  SET_EMMA_DATA,
  ADD_ACCOUNT,
  TEMP_ACCOUNT,
  UPDATE_ACCOUNT,
  DEL_ACCOUNT,
  SET_TRANSID,
};

// Actions
export function setTransId(id) {
  return {
    type: SET_TRANSID,
    id,
  };
}
export function addAllotAccount(account) {
  return {
    type: ADD_ACCOUNT,
    account,
  };
}

export function updateTempAllotAccount(data) {
  return {
    type: TEMP_ACCOUNT,
    index: data.index,
    account: data.account,
  };
}

export function updateAllotAccount(data) {
  return {
    type: UPDATE_ACCOUNT,
    index: data.index,
    account: data.account,
  };
}

export function delAllotAccount(index) {
  return {
    type: DEL_ACCOUNT,
    index,
  };
}

export function searchHelpCenterCategoryMenu(menu) {
  return {
    type: SEARCH_HELP_CENTER_CATEGORY_MENU,
    menu,
  };
}

export function searchHelpCenterKeyword(payload) {
  return {
    type: SEARCH_HELP_CENTER_KEYWORD,
    keyword: payload.keyword,
    storeType: payload.storeType,
  };
}

export function searchHelpCenterHotkey(hot) {
  return {
    type: SEARCH_HELP_CENTER_HOTKEY,
    hot,
  };
}

export function searchHelpCenterCategory(category) {
  return {
    type: SEARCH_HELP_CENTER_HOTKEY,
    category,
  };
}

export function sendFormGenderData(payload) {
  return {
    type: SEND_FORM_GENDER_DATA,
    payload,
  };
}

export function sendFormSelectData(payload) {
  return {
    type: SEND_FORM_SELECT_DATA,
    payload,
  };
}

export function sendFormRecaptcha(payload) {
  return {
    type: SEND_FORM_RECAPTCHA,
    payload,
  };
}

export function sendFormAgreetext(payload) {
  return {
    type: SEND_FORM_AGREETEXT,
    payload,
  };
}

export function sendFormInputData(payload) {
  return {
    type: SEND_FORM_INPUT_DATA,
    payload,
  };
}

export function sendFormCustomData(payload) {
  return {
    type: SEND_FORM_CUSTOM_DATA,
    payload,
  };
}

export function setHelpCenterSearchPath(url) {
  return { type: SET_HELP_CENTER_SEARCHPATH, url };
}

export function setDataLayer(payload) {
  return {
    type: SET_DATALAYER,
    payload,
  };
}

export function sendCustomEvent(eventName) {
  return {
    type: SEND_CUSTOM_EVENT,
    eventName,
  };
}

export function trackPageViewStart(payload) {
  return {
    type: TRACK_PAGE_VIEW_START,
    payload,
  };
}

export function trackPageViewEnd(payload) {
  return {
    type: TRACK_PAGE_VIEW_END,
    payload,
  };
}

export function trackActionTrigger(payload) {
  return {
    type: TRACK_ACTION_TRIGGER,
    payload,
  };
}

export function trackActionButton(payload) {
  return {
    type: TRACK_ACTION_BUTTON,
    payload,
  };
}

export function trackPurchaseCompleted(payload) {
  return {
    type: TRACK_PURCHASE_COMPLETED,
    payload,
  };
}

export function trackPurchaseFailure(payload) {
  return {
    type: TRACK_PURCHASE_FAILURE,
    payload,
  };
}

export function trackSearchItemClick(payload) {
  return {
    type: TRACK_SEARCH_ITEM_CLICK,
    payload,
  };
}

export function trackShareButtonClick(payload) {
  return {
    type: TRACK_SHARE_BUTTON_CLICK,
    payload,
  };
}

export function trackFaqFillIn(payload) {
  return {
    type: TRACK_FAQ_FILL_IN,
    payload,
  };
}

export function setEmmaData(data) {
  return { type: SET_EMMA_DATA, data };
}
