import React from 'react';
import { Route } from 'react-router-dom';

import { renderPageComponent } from './renderPageComponent';
import pagesData from './routes';

const pageRoutes = pagesData.map(({ name, path, component, pageType }) => {
  return <Route key={name} path={path} exact render={() => renderPageComponent(component, pageType)} />;
});

export default pageRoutes;
