/**
 * 登入驗證錯誤公版，
 * propData 等於上一層的props
 */
export const ToAuthorizationErrorPage = (propData) => {
  // 因 eservice 頁面天地會用 channelId 判斷是否顯示
  // 在導頁的時候須把 channelId 一並帶入
  propData.history.push(
    {
      pathname: '/resultController/ResultPage',
      search: '?channelId=' + window.channelId,
    },
    {
      type: 'fail',
      pageName: '',
      title: '',
      description: '您登入的門號/帳號不適用此服務，謝謝!',
      actions: [
        {
          text: '前往個人專區',
          link: process.env.REACT_APP_HOME_PAGE + window.channelId,
          btnStyle: 'primary',
        },
      ],
    }
  );
};
