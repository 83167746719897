import React from 'react';
import PropTypes from 'prop-types';
import Link from './Link';
import Cookies from 'js-cookie';
// import CloseIcon from './CloseIcon';

export default class NotificationBar extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    closeIconStyles: PropTypes.object,
    sound: PropTypes.bool,
  };

  static defaultProps = {
    sound: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      delay: this.props.second,
      cookieName: this.props.type !== 'app-download' ? 'TopBanner' : 'AppDownloadBanner',
    };

    this.storageKey = this.state.cookieName;
  }

  componentDidMount() {
    setTimeout(() => {
      this.open();
      this.state.delay > 0 &&
        setTimeout(() => {
          this.close();
        }, this.state.delay * 1000);

      if (this.props.onLoad) this.props.onLoad();
    }, 1000);
  }

  close = (e) => {
    if (e) e.preventDefault();

    this.setState({
      open: false,
    });

    if (this.props.onClose) this.props.onClose();
  };

  open() {
    this.saveOpenState();
    this.setState({
      open: true,
    });
  }

  saveOpenState() {
    const notifications = !!Cookies.get(this.storageKey) ? JSON.parse(Cookies.get(this.storageKey)) : {};
    Cookies.set(
      this.storageKey,
      {
        open: parseInt(notifications.open || 0) + 1,
        click: notifications.click || 0,
      },
      { expires: 7 }
    );
  }

  saveClickState = () => {
    const notifications = !!Cookies.get(this.storageKey) ? JSON.parse(Cookies.get(this.storageKey)) : {};
    Cookies.set(
      this.storageKey,
      {
        open: notifications.open || 0,
        click: parseInt(notifications.click || 0) + 1,
      },
      { expires: 7 }
    );
  };

  typeHandler = (props) => {
    switch (props.type) {
      case 'normal':
        return (
          <span>
            {props.title}&nbsp;
            <Link
              to={props.link}
              target={props.target}
              onClick={(event) => {
                this.saveClickState();
                // [AA Tracking]
                if (window && typeof window.ctrf === 'function')
                  window.ctrf(event || {}, `${props.title}_${props.btn}`);
              }}
            >
              {props.btn}
            </Link>
          </span>
        );

      case 'image-bulletin':
        return (
          <Link
            to={props.link}
            target={props.target}
            onClick={
              (this.saveClickState,
              () => {
                // [AA Tracking]
                if (window && window.s_objectID !== undefined) window.s_objectID = `${props.image.alt}`;
              })
            }
          >
            <img src={props.image.md} className='d-none d-md-block' alt={props.image.alt} />
            <img src={props.image.sm} className='d-block d-md-none' alt={props.image.alt} />
          </Link>
        );

      case 'app-download':
        return (
          <div className='app-download-container'>
            <img src={props.image.appIcon} alt={props.image.alt} />
            <div className='content'>
              <div>{props.title}</div>
              <div>
                <small>{props.appDesc}</small>
              </div>
              <small>{props.appText}</small>
            </div>
            <div className='extra'>
              <Link to={props.link} target={props.target} onClick={this.saveClickState}>
                {props.btn}
              </Link>
            </div>
          </div>
        );

      default:
        return this.props.children;
    }
  };

  render() {
    return (
      <div
        className={`NotificationBar ${this.props.className ? this.props.className : ''} ${
          this.state.open ? 'open' : 'closed'
        }`}
        id='Top_Banner'
      >
        <div className='NotificationBar__message'>{this.typeHandler(this.props)}</div>
        <a
          className='NotificationBar__close'
          onClick={(event) => {
            this.close();
            // [AA Tracking]
            let text = '';
            switch (this.props.type) {
              case 'normal':
                text = `Tab_關閉_${this.props.btn}`;
                break;
              case 'image-bulletin':
                text = `Tab_關閉_${this.props.image.alt}`;
                break;
              default:
                break;
            }
            if (window && typeof window.ctrf === 'function') window.ctrf(event || {}, text);
          }}
          href='javascript:void(0)'
        >
          <i className='icon-close'></i>
        </a>
      </div>
    );
  }
}
