import React from 'react';
import Helmet from 'react-helmet';
import * as GetFrontEndSettingService from '../../../service/GetFrontEndSettingService';
import * as validation from '../../../utils/validation';
import Axios from 'axios';
import * as callApiUtil from '../../../utils/callApiUtil';
import CloudHeader from '../header/cloudHeader';
import NavAnchor from '../../../components/partials/NavAnchor';

class newHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nonJQueryHTML: null,
      loginStatus: null,
      isLoadingLoginStatus: true,
      isLoadingMetaDataTag: true,
      isLoadHeaderDone: false,
      baseUrl: process.env.REACT_APP_HEADER_URL,
    };
  }

  componentDidMount() {
    callApiUtil
      .callApi('/eservice/api/authorizationController/getLoginStatus', {})
      .then((data) => {
        if (data && data.processResult && data.processResult.status == 's') {
          this.setState({
            loginStatus: data.loginStatus,
          });
        }
      })
      .finally(() => {
        this.setState({
          isLoadingLoginStatus: false,
        });
      });

    // 後端取得各頁面的 MetaTag 並串在 Header 上
    GetFrontEndSettingService.getMetaDataTag(window.location.pathname)
      .then((data) => {
        document.querySelector('head').innerHTML += data.metaData;
      })
      .finally(() => {
        this.setState({
          isLoadingMetaDataTag: false,
        });
      });
  }

  componentDidUpdate() {
    if (!this.state.isLoadHeaderDone && !this.state.isLoadingLoginStatus && !this.state.isLoadingMetaDataTag) {
      this.setState({
        isLoadHeaderDone: true,
      });
      this.props.callback();
    }
  }

  render() {
    // 設定登出導頁
    // if (window['fetnet_header_setLogoutLink']) {
    //   window['fetnet_header_setLogoutLink'](process.env.REACT_APP_LOGOUT_REDIRECT_URL);
    // }

    // 設定 Header 登入選項
    if (window['fetnet_menu_showDesktop']) {
      window['fetnet_menu_showDesktop'](this.state.loginStatus, 'NA');
    }

    // 設定 Header 搜尋頁面
    if (window['fetnet_header_setSearch']) {
      window['fetnet_header_setSearch']('https://www.fetnet.net/content/cbu/tw/search.html?search_type=eservice');
    }

    return (
      <React.Fragment>
        <Helmet>
          <link rel='stylesheet' href={this.state.baseUrl + '/css/mothra-header-footer.css'} />
          <script src={this.state.baseUrl + '/js/jquery.ui.min.js'} defer></script>
          <script src={this.state.baseUrl + '/js/jquery.selectBoxIt.min.js'} defer></script>
          <script src={this.state.baseUrl + '/home/rwd/CommonPage/js/fetnet_breadcrumb.js'} defer></script>
          {/* <script src={this.state.baseUrl + '/home/rwd/CommonPage/js/eservice_header.js'}></script> */}
          <script src={this.state.baseUrl + '/home/rwd/CommonPage/js/fetnet_menu.js'} defer></script>
          <script src={this.state.baseUrl + '/home/rwd/CommonPage/js/fetnet_webtrend_rwd.js'} defer></script>
          <script src={this.state.baseUrl + '/home/rwd/CommonPage/js/fetnet_common.js'} defer></script>
          <script src={this.state.baseUrl + '/CommonPage/js/fetnet_ssoProcessRWD.js'} defer></script>
          <script src={this.state.baseUrl + '/chatrobot/onlinechat/attributes/js/bundle/postMsg.js'} defer></script>
          <script src={this.state.baseUrl + '/js/mothra.js'} defer></script>
        </Helmet>
        <CloudHeader></CloudHeader>
      </React.Fragment>
    );
  }
}

export default newHeader;
