import React, { useState, useEffect, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ErrorBoundary } from 'react-error-boundary';

import * as Action from './stores/actions';
import pageRoutes from './router';
import { useVisibility } from './context/VisibilityContext';

import SuspenseLoader from './components/SuspenseLoader';
import Loader from './components/Loader';
import TopBanner from './components/TopBanner';
import Header from './components/partials/header/Header';

const Footer = lazy(() => import('./components/partials/footer/Footer'));
const Error = lazy(() => import('./pages/Error'));

const query = new URLSearchParams(window.location.search);
// #5634 調整 channelId
// 與 IT 奕盛確認目前其他系統與 eservice 介接的方式以 URL 為主，會在 queryParam 傳入 channelId
// 預設值: eservice
if (query.get('channelId')) {
  window.channelId = query.get('channelId');
} else {
  window.channelId = 'eservice';
}

let path = '';
// 如果按瀏覽器上一頁，則 reload 畫面
if (typeof window !== 'undefined') {
  window.addEventListener('popstate', function () {
    window.location.reload(true);
  });

  path = window.location.pathname;
}

const App = () => {
  const [isSuperApp] = useState(window.channelId === 'SUPERAPP');
  const [isInternalAgent] = useState(window.channelId === 'internalAgent');
  const [isAppWebView] = useState(navigator.userAgent.includes('fet/') || isSuperApp || isInternalAgent);
  const [isHeaderReady, setHeaderReady] = useState(false);
  const { setShowContent } = useVisibility();
  const showContent = (!isAppWebView && isHeaderReady) || isAppWebView;

  useEffect(() => {
    if (showContent) setShowContent(true);
  }, [showContent, setShowContent]);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      {/* 
        舊有的 <Loader /> (電波訊號)，因送出表單或部分 API Request 會開啟，
        並且會在 html、body tag 有動態操作 classname，目前尚不能移除。
      */}
      <Loader />

      {!isAppWebView ? (
        <ErrorBoundary fallback={null}>
          <TopBanner />
          <Header callback={() => setHeaderReady(true)} />
        </ErrorBoundary>
      ) : null}

      <ParallaxProvider>
        <Suspense fallback={<SuspenseLoader />}>
          <ErrorBoundary fallback={<Error />}>
            <Switch>
              {pageRoutes}

              {/*預設導個人專區首頁* */}
              {/* 個人_主頁 */}
              <Route
                path='/'
                exact
                render={(props) =>
                  props.history.push('/personalAreaController/personalAreaLayout?channelId=' + window.channelId)
                }
              />
              <Route component={Error} />
            </Switch>
          </ErrorBoundary>
        </Suspense>
      </ParallaxProvider>

      {!isAppWebView ? (
        <Suspense fallback={null}>
          <ErrorBoundary fallback={null}>
            <Footer />
          </ErrorBoundary>
        </Suspense>
      ) : null}
    </Router>
  );
};

const ReduxExample = connect((state) => state, { ...Action })(App);

export default ReduxExample;
