import * as callApiUtil from '../utils/callApiUtil';
import moment from 'moment';
import axios from 'axios';

export const EnvironmentURLTitleVOut = async () => {
  return callApiUtil.callApi('/eservice/api/generalInfoController/queryEnvironmentURLTitle', {}).then((response) => {
    return response;
  });
};

export const getMetaDataTag = async (path) => {
  return callApiUtil
    .callApi('/eservice/api/metaTagController/getMetaDataTagByPath', {
      path: path,
    })
    .then((response) => {
      return response;
    });
};

export const getHtmlCode = async (url) => {
  return axios
    .get(url)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {});
};

export const getFAQ = async (faqId) => {
  return axios
    .get(process.env.REACT_APP_FAQ + faqId + '&ts=' + moment(new Date()).format('YYYYMMDDHH'))
    .then((result) => {
      return result;
    })
    .catch((error) => {});
};
